import React from "react";
import AppBar from "@mui/material/AppBar";
import { useDispatch, useSelector } from "react-redux";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { DataGrid } from "@mui/x-data-grid";
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import {
  interventions,
  pacchetti,
  task,
  changeStatusGraph,
} from "../../store/slice/userSlice";
import Tempo from "../Tempo/Tempo";
import Utente from "../Utente/Utente";
import { loaded } from "../../store/slice/loadSlice";
import { tryLogout } from "../../store/slice/loginSlice";
import LinearProgress from "@mui/material/LinearProgress";
import stringWidth from "string-width";
import { operatori } from "../../store/slice/operatoreSlice";
import TabContext from "@mui/lab/TabContext";
import TabPanel from "@mui/lab/TabPanel";
import TabList from "@mui/lab/TabList";
import Tab from "@mui/material/Tab";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import ListaPacchetti from "../ListaPacchetti/ListaPacchetti";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import CreaPDF from "../CreaPDF/CreaPDF";
import Statistiche from "../Statistiche/Statistiche";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import ClearIcon from "@mui/icons-material/Clear";
import IconButton from "@mui/material/IconButton";
import logoWhite from "../../Img/LogoWhite.svg";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import EmailIcon from "@mui/icons-material/Email";
import LanguageIcon from "@mui/icons-material/Language";

function decodeHtml(html) {
  var txt = document.createElement("textarea");
  txt.innerHTML = html;
  return txt.value;
}

export default function ButtonAppBar() {
  const interventi = useSelector(interventions);
  const load = useSelector(loaded);
  const op = useSelector(operatori);
  const ta = useSelector(task);
  const dispatch = useDispatch();
  const pack = useSelector(pacchetti);

  const [value, setValue] = React.useState("1");
  const [ope, setOp] = React.useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const descLength = interventi?.map((i) => stringWidth(i.text));
  const lengthMax = Math.max(...descLength);

  const rows2 = ta?.length
    ? ta?.map((i) => {
        let desc = decodeHtml(i.info);

        const operatore = op.find((x) => x.id === i.user_id);

        const stato = i.todo_status_id === 1 ? "Aperto" : "Fatto";

        return {
          id: i.id,
          descrizione: desc,
          operatore: `${operatore?.firstname} ${operatore?.lastname}`,
          data: i.finish_date,
          titolo: i.subject,
          stato: stato,
        };
      })
    : "";

  const rows = interventi?.length
    ? interventi?.map((i) => {
        const desc1 = i.text.replaceAll("<ul>", " ");
        const desc2 = desc1.replaceAll("</ul>", " ");
        const desc3 = desc2.replaceAll("<li>", " ");
        const desc4 = desc3.replaceAll("</li>", " ");
        let desc5 = desc4.replaceAll("<br />", " ");
        let desc6 = decodeHtml(desc5);

        const operatore = op.find((x) => x.id === i.user_id);
        let packet;

        if (
          pack.headers !== undefined &&
          Object.keys(pack?.headers).length !== 0
        ) {
          packet = pack?.headers?.find((x) => x.id === i.pr_package_id);
        }

        const fatt = i.status_id === 3 ? "Da fatturare" : "fatturato";

        return {
          id: i.id,
          descrizione: desc6,
          operatore: `${operatore?.firstname} ${operatore?.lastname}`,
          data: i.date,
          tempo: i.duration,
          pacchetto: packet?.name,
          fattura: fatt,
        };
      })
    : "";
  const columns = [
    {
      field: "data",
      headerName: "Data",
      type: "data",
      width: 100,

      editable: false,
      sortable: false,
    },

    {
      field: "tempo",
      headerName: "Tempo (hh:mm)",
      type: "data",
      editable: false,
      sortable: false,
      width: 150,
    },
    {
      field: "operatore",
      headerName: "Operatore",
      editable: false,
      sortable: false,
      width: 150,
    },
    {
      field:
        pack.headers !== undefined && Object.keys(pack?.headers).length !== 0
          ? "pacchetto"
          : "fattura",
      headerName:
        pack.headers !== undefined && Object.keys(pack?.headers).length !== 0
          ? "Pacchetto"
          : "Fattura",
      width: 220,
      editable: false,
      sortable: false,
    },
    {
      field: "descrizione",
      headerName: "Descrizione",
      width: lengthMax * 6,
      editable: false,
      sortable: false,
    },
  ];

  const columns2 = [
    {
      field: "stato",
      headerName: "Stato",
      type: "data",
      width: 100,
      editable: false,
      sortable: false,
    },
    {
      field: "data",
      headerName: "Data termine",
      type: "data",
      width: 180,
      editable: false,
      sortable: false,
    },

    {
      field: "titolo",
      headerName: "Titolo",
      editable: false,
      sortable: false,
      width: 150,
    },
    {
      field: "operatore",
      headerName: "Operatore",
      editable: false,
      sortable: false,
      width: 150,
    },
    {
      field: "descrizione",
      headerName: "Descrizione",
      width: lengthMax * 6,
      editable: false,
      sortable: false,
    },
  ];

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Dialog
        onClose={() => {
          setOp(false);
        }}
        open={ope}
      >
        <AppBar style={{ backgroundColor: "#34538a" }} position="static">
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography style={{ margin: "20px" }} variant="h5">
              PDF Interventi
            </Typography>
            <IconButton onClick={() => setOp(false)}>
              <ClearIcon style={{ margin: "20px", color: "white" }} />
            </IconButton>
          </Grid>
        </AppBar>
        <CreaPDF />
      </Dialog>
      <Statistiche></Statistiche>
      <ListaPacchetti />
      <AppBar position="static" style={{ backgroundColor: "#34538a" }}>
        <Toolbar>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <img alt="logo" src={logoWhite} style={{ width: "200px" }}></img>

            <Button
              style={{ color: "white" }}
              href="tel:+41912221200"
              variant="text"
              startIcon={<LocalPhoneIcon style={{ fontSize: "25px" }} />}
            >
              <Typography variant="h6">+41 91 222 12 00</Typography>
            </Button>

            <Button
              style={{ color: "white" }}
              href="mailto:info@apton.ch"
              variant="text"
              startIcon={<EmailIcon style={{ fontSize: "25px" }} />}
            >
              <Typography variant="h6">info@apton.ch</Typography>
            </Button>

            <Button
              style={{ color: "white" }}
              href="https://www.apton.cloud"
              variant="text"
              startIcon={<LanguageIcon style={{ fontSize: "25px" }} />}
            >
              <Typography variant="h6"> www.apton.cloud</Typography>
            </Button>

            <Button
              variant="outlined"
              onClick={() => dispatch(tryLogout())}
              color="inherit"
              style={{ fontSize: "20px" }}
            >
              Logout
            </Button>
          </Grid>
        </Toolbar>
      </AppBar>
      {!load ? (
        <div style={{ height: "1400px" }}>
          <LinearProgress style={{ height: "10px" }} />{" "}
        </div>
      ) : (
        <div style={{ padding: "3%" }}>
          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
          >
            <Button
              startIcon={<QueryStatsIcon />}
              variant="contained"
              style={{
                backgroundColor: "#bfd825",
                color: "black",
                margin: "15px",
              }}
              onClick={() => dispatch(changeStatusGraph())}
            >
              Grafico ore
            </Button>
            <Button
              startIcon={<PictureAsPdfIcon />}
              variant="contained"
              style={{
                backgroundColor: "#bfd825",
                color: "black",
                margin: "15px",
              }}
              onClick={() => setOp(true)}
            >
              Crea PDF
            </Button>
          </Grid>
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            <Grid item xs={12} md={6}>
              <Tempo />
            </Grid>
            <Grid item xs={12} md={6}>
              <Utente />
            </Grid>
          </Grid>

          <Paper style={{ padding: "10px", paddingTop: "1px", margin: "0.5%" }}>
            <TabContext style={{ backgroundColor: "#104c80" }} value={value}>
              <TabList
                style={{ marginLeft: "30px" }}
                onChange={handleChange}
                aria-label="lab API tabs example"
                textColor="primary"
                indicatorColor="primary"
              >
                <Tab
                  icon={<SupportAgentIcon />}
                  iconPosition="start"
                  label="Interventi"
                  value="1"
                />
                <Tab
                  icon={<AssignmentTurnedInIcon />}
                  iconPosition="start"
                  label="Task"
                  value="2"
                />
              </TabList>
              <TabPanel value="1">
                <div style={{ height: 450, width: "100%" }}>
                  <DataGrid
                    rows={rows}
                    columns={columns}
                    pageSize={6}
                    disableSelectionOnClick
                  />
                </div>
              </TabPanel>
              <TabPanel value="2">
                <div style={{ height: 450, width: "100%" }}>
                  <DataGrid
                    rows={rows2}
                    columns={columns2}
                    pageSize={6}
                    disableSelectionOnClick
                  />
                </div>
              </TabPanel>
            </TabContext>
          </Paper>
        </div>
      )}
    </Box>
  );
}
