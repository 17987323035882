import axios from "axios";
import feathers from "@feathersjs/client";
import rest from "@feathersjs/rest-client";

const app = feathers();

// Connect to a different URL
const restClient = rest("http://localhost:3030");

// Configure an AJAX library (see below) with that client
app.configure(restClient.axios(axios));

// Connect to the service
const login = app.service("login");
const clienti = app.service("clienti");
const interventi = app.service("interventi");
const pacchetti = app.service("pacchetti");
const nazioni = app.service("nazioni");
const account = app.service("account");
const task = app.service("task");

/**
 * Prende formulari senza etchetta
 */

export const fetchLogin = (user, pass) =>
  login.find({
    query: {
      Username: user,
      Password: pass,
    },
  });

export const getUser = (user) =>
  clienti.find({
    query: {
      Id: user,
    },
  });

export const getInterventions = (id) =>
  interventi.find({
    query: {
      Id: id,
    },
  });

export const getPackage = (id) =>
  pacchetti.find({
    query: {
      Id: id,
    },
  });
export const getCountry = (id) =>
  nazioni.find({
    query: {
      Id: id,
    },
  });
export const updateUser = (id, data) => clienti.update(id, data);

export const getOperatore = () => account.find();

export const getTask = (id) =>
  task.find({
    query: {
      id,
    },
  });
