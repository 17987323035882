import React from "react";
import Dialog from "@mui/material/Dialog";
import { useSelector, useDispatch } from "react-redux";
import {
  changeStatusDialog,
  openDialog,
  pacchetti,
} from "../../store/slice/userSlice";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import AppBar from "@mui/material/AppBar";
import ClearIcon from "@mui/icons-material/Clear";
import IconButton from "@mui/material/IconButton";

export default function ListaPacchetti() {
  const dialog = useSelector(openDialog);
  const dispatch = useDispatch();
  const pack = useSelector(pacchetti);
  let rowPack;
  let sumOre = 0;
  let sumHour = 0;
  let sumMin = 0;
  if (pack.headers !== undefined && Object.keys(pack?.headers).length !== 0) {
    rowPack = pack?.headers?.map((pacchetto) => {
      const ore = Math.trunc(pacchetto.spent_time_in_hours);
      const minuti = Math.trunc((pacchetto.spent_time_in_hours - ore) * 60);
      sumOre = sumOre + pacchetto.estimated_time_in_hours;
      sumHour = sumHour + ore;
      sumMin = sumMin + minuti;
      if (sumMin > 59) {
        const oreAgg = Math.trunc(sumMin / 60);
        sumHour = sumHour + oreAgg;
        sumMin = (sumMin / 60 - oreAgg) * 60;
      }
      return (
        <TableRow sx={{}}>
          <TableCell component="th" scope="row">
            {pacchetto.name}
          </TableCell>
          <TableCell component="th" scope="row">
            {pacchetto.estimated_time_in_hours}
          </TableCell>
          <TableCell component="th" scope="row">
            {ore}:{minuti}
          </TableCell>
        </TableRow>
      );
    });
  }

  return (
    <Dialog onClose={() => dispatch(changeStatusDialog())} open={dialog}>
      <AppBar style={{ backgroundColor: "#34538a" }} position="static">
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography style={{ margin: "20px" }} variant="h5">
            Lista pacchetti
          </Typography>
          <IconButton onClick={() => dispatch(changeStatusDialog())}>
            <ClearIcon style={{ margin: "20px", color: "white" }} />
          </IconButton>
        </Grid>
      </AppBar>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Nome</TableCell>
            <TableCell>Ore pacchetto</TableCell>
            <TableCell>Ore usate (hh:mm)</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>{rowPack}</TableBody>
        <TableRow>
          <TableCell>
            <Typography variant="h5" style={{ color: "#bfd825" }}>
              Totale
            </Typography>
          </TableCell>
          <TableCell>
            {" "}
            <Typography variant="h5" style={{ color: "#bfd825" }}>
              {sumOre}
            </Typography>
          </TableCell>
          <TableCell>
            <Typography variant="h5" style={{ color: "#bfd825" }}>
              {sumHour}:{sumMin}
            </Typography>
          </TableCell>
        </TableRow>
      </Table>
    </Dialog>
  );
}
