import React from "react";
import { useSelector } from "react-redux";
import { Page, Text, View, Document, Image } from "@react-pdf/renderer";
import { PDFViewer } from "@react-pdf/renderer";
import { user, nazione } from "../../store/slice/userSlice";
import { pacchetti } from "../../store/slice/userSlice";
import { operatori } from "../../store/slice/operatoreSlice";
import Apton from "../../Img/Apton.png";

export default function PDF(props) {
  const utente = useSelector(user);
  const country = useSelector(nazione);
  const interventi = props.interventi;
  const op = useSelector(operatori);
  const pack = useSelector(pacchetti);

  const packSelected = pack?.headers?.find(
    (p) => p.id === props.interventi[0].pr_package_id
  );

  var tempoPacchetto = 0;
  var tempoUsato = 0;
  var tempoMinuti = 0;
  var tempoOre = 0;
  var perc = 0;
  if (pack.headers !== undefined && Object.keys(pack?.headers).length !== 0) {
    tempoPacchetto = packSelected.estimated_time_in_hours;
    tempoUsato = packSelected.spent_time_in_hours;
    tempoMinuti = Math.round(
      (packSelected.spent_time_in_hours -
        Math.trunc(packSelected.spent_time_in_hours)) *
        60
    );
    tempoOre = Math.trunc(packSelected.spent_time_in_hours);
    perc = Math.round((100 / tempoPacchetto) * tempoUsato * 100) / 100;
  }

  var today = new Date().toLocaleDateString();
  const inizio = props.dal.toLocaleDateString();
  const fine = props.al.toLocaleDateString();
  const intervents = interventi?.map((i) => {
    const desc1 = i.text.replaceAll("<ul>", " ");
    const desc2 = desc1.replaceAll("</ul>", " ");
    const desc3 = desc2.replaceAll("<li>", " ");
    const desc4 = desc3.replaceAll("</li>", " ");
    let desc5 = desc4.replaceAll("<br />", " ");

    const operatore = op.find((x) => x.id === i.user_id);

    let packet;

    if (pack.headers !== undefined && Object.keys(pack?.headers).length !== 0) {
      packet = pack?.headers?.find((x) => x.id === i.pr_package_id);
    }

    return (
      <View
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-around",
          alignContent: "stretch",
          flexWrap: "nowrap",
          alignItems: "stretch",
        }}
      >
        <Text
          style={{
            borderColor: "#efece5",
            borderStyle: "solid",
            borderWidth: 2,
            flexGrow: 1,
            flexShrink: 1,
            flexBasis: "auto",
            alignSelf: "stretch",
            padding: "10px",
          }}
          key={i.id}
        >
          {" "}
          {i.date}
        </Text>
        <Text
          style={{
            borderColor: "#efece5",
            borderStyle: "solid",
            borderWidth: 2,
            flexGrow: 0.7,
            flexShrink: 1,
            flexBasis: "auto",
            alignSelf: "stretch",
            padding: "10px",
          }}
          key={i.id}
        >
          {i.duration}
        </Text>

        <Text
          style={{
            borderColor: "#efece5",
            borderStyle: "solid",
            borderWidth: 2,
            flexGrow: 1,
            flexShrink: 1,
            flexBasis: "auto",
            alignSelf: "stretch",
            padding: "10px",
          }}
          key={i.id}
        >
          {operatore?.firstname}
          {"\n"}
          {operatore?.lastname}
        </Text>
        <Text
          style={{
            borderColor: "#efece5",
            borderStyle: "solid",
            borderWidth: 2,
            flexGrow: 1,
            flexShrink: 1,
            flexBasis: "auto",
            alignSelf: "stretch",
            padding: "10px",
          }}
          key={i.id}
        >
          {packet?.name}
        </Text>
        <Text
          style={{
            borderColor: "#efece5",
            borderStyle: "solid",
            borderWidth: 2,
            flexGrow: 4,
            flexShrink: 2,
            flexBasis: "auto",
            alignSelf: "stretch",
            padding: "10px",
          }}
          key={i.id}
        >
          {desc5}
        </Text>
      </View>
    );
  });

  if (utente !== undefined && country !== undefined) {
    return (
      <PDFViewer
        style={{
          height: window.screen.height,
          width: window.screen.width / 2,
        }}
      >
        <Document>
          <Page style={{ padding: "20px", flexDirection: "column" }} size="A4">
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignContent: "stretch",
                flexWrap: "nowrap",
                alignItems: "stretch",
                borderBottom: "1px solid black",
              }}
            >
              <Image
                style={{ width: "120px", marginBottom: "20px" }}
                src={Apton}
              ></Image>
              <Text
                style={{
                  fontSize: 15,
                }}
              >
                {today}
              </Text>
            </View>

            <View>
              {props.alignment === "pacchetto" ? (
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 30,
                    marginBottom: "30px",
                    marginTop: "30px",
                  }}
                >
                  Interventi del {packSelected?.name}
                </Text>
              ) : (
                <></>
              )}
              {props.alignment === "periodo" ? (
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 30,
                    marginBottom: "30px",
                    marginTop: "30px",
                  }}
                >
                  Interventi dal {inizio} al {fine}
                </Text>
              ) : (
                <></>
              )}
              {props.alignment === "tutti" ? (
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 30,
                    marginBottom: "30px",
                    marginTop: "30px",
                  }}
                >
                  Tutti i nostri interventi
                </Text>
              ) : (
                <></>
              )}
            </View>
            <View style={{ marginBottom: "20px" }}>
              <Text style={{ fontSize: 15, textAlign: "left" }}>
                {utente.name_1}
              </Text>
              <Text style={{ fontSize: 15, textAlign: "left" }}>
                {utente.address}
              </Text>
              <Text style={{ fontSize: 15, textAlign: "left" }}>
                {utente.postcode} {utente.city}
                {", "} {country.name_short}
              </Text>
            </View>
            {props.alignment === "pacchetto" ? (
              <View style={{ marginBottom: "20px" }}>
                <Text style={{ fontSize: 15, textAlign: "left" }}>
                  Tempo usato {tempoOre} h {tempoMinuti} min
                </Text>
                <Text style={{ fontSize: 15, textAlign: "left" }}>
                  Percentuale usata {perc}%
                </Text>
              </View>
            ) : (
              <></>
            )}
            <View
              style={{
                fontSize: 10,
                width: 550,
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                alignContent: "stretch",
                flexWrap: "nowrap",
                alignItems: "stretch",
              }}
            >
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-around",
                  alignContent: "stretch",
                  flexWrap: "nowrap",
                  alignItems: "stretch",
                }}
              >
                <Text
                  style={{
                    backgroundColor: "#dbcbbb",
                    borderColor: "#efece5",
                    borderStyle: "solid",
                    borderWidth: 2,
                    flexGrow: 1,
                    flexShrink: 1,
                    flexBasis: "auto",
                    alignSelf: "stretch",
                    fontSize: 11,
                    fontWeight: 1200,
                    color: "#1a245c",
                    padding: "10px",
                  }}
                >
                  Data
                </Text>
                <Text
                  style={{
                    backgroundColor: "#dbcbbb",
                    borderColor: "#efece5",
                    borderStyle: "solid",
                    borderWidth: 2,
                    flexGrow: 0.7,
                    flexShrink: 1,
                    flexBasis: "auto",
                    alignSelf: "stretch",
                    fontSize: 11,
                    fontWeight: 1200,
                    color: "#1a245c",
                    padding: "10px",
                  }}
                >
                  Tempo
                </Text>
                <Text
                  style={{
                    backgroundColor: "#dbcbbb",
                    borderColor: "#efece5",
                    borderStyle: "solid",
                    borderWidth: 2,
                    flexGrow: 1,
                    flexShrink: 1,
                    flexBasis: "auto",
                    alignSelf: "stretch",
                    fontSize: 11,
                    fontWeight: 1200,
                    color: "#1a245c",
                    padding: "10px",
                  }}
                >
                  Operatore
                </Text>
                <Text
                  style={{
                    backgroundColor: "#dbcbbb",
                    borderColor: "#efece5",
                    borderStyle: "solid",
                    borderWidth: 2,
                    flexGrow: 1,
                    flexShrink: 1,
                    flexBasis: "auto",
                    alignSelf: "stretch",
                    fontSize: 11,
                    fontWeight: 1200,
                    color: "#1a245c",
                    padding: "10px",
                  }}
                >
                  Pacchetto
                </Text>
                <Text
                  style={{
                    backgroundColor: "#dbcbbb",
                    borderColor: "#efece5",
                    borderStyle: "solid",
                    borderWidth: 2,
                    flexGrow: 4,
                    flexShrink: 2,
                    flexBasis: "auto",
                    alignSelf: "stretch",
                    fontSize: 11,
                    fontWeight: 1200,
                    color: "#1a245c",
                    padding: "10px",
                    paddingRight: "10px",
                  }}
                >
                  Descrizione
                </Text>
              </View>
              {intervents}
            </View>
          </Page>
        </Document>
      </PDFViewer>
    );
  }
}
