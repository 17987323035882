
import { createSlice } from '@reduxjs/toolkit';

const operatoreSlice = createSlice({
  name: 'operatore',
  initialState:
  {
    operatori:[],

  },
  reducers: {
    setOperatori(state, {payload}) {
      state.operatori = payload;
    },

  },
});

export const operatori = (state) => state.operatore.operatori;


export const {
  setOperatori
} = operatoreSlice.actions;
export default operatoreSlice.reducer;
