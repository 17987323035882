import { configureStore } from '@reduxjs/toolkit';
import logger from 'redux-logger';
import createSagaMiddleware from 'redux-saga';
import rootSaga from '../sagas';
import login from '../slice/loginSlice';
import user from '../slice/userSlice';
import load from '../slice/loadSlice';
import operatore from '../slice/operatoreSlice';

const sagaMiddleware = createSagaMiddleware();

const store = configureStore({
  reducer: {
    login,
    user,
    load,
    operatore,
  },

  middleware: [sagaMiddleware, logger],
  devTools: process.env.NODE_ENV !== 'production',

});
sagaMiddleware.run(rootSaga);
export default store;
