import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {
  changeStatusDialog,
  interventions,
  pacchetti,
} from "../../store/slice/userSlice";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { styled } from "@mui/material/styles";
import tempo1 from "../../Img/Tempo1.png";
import Button from "@mui/material/Button";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  marginTop: "4em",
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: "#fff",
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#72849c" : "#72849c",
  },
}));

const BorderLinearProgress2 = styled(LinearProgress)(({ theme }) => ({
  marginTop: "5px",
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: "#fff",
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#bfd825" : "#bfd825",
  },
}));

export default function Login() {
  const dispatch = useDispatch();
  const pack = useSelector(pacchetti);
  const interventi = useSelector(interventions);

  const interventiDone = interventi?.filter(
    (x) => x.status_id === 3 && x.duration !== null
  );
  const numDoneHours = interventiDone
    ?.map((item) => parseInt(item?.duration))
    .reduce((prev, curr) => prev + curr, 0);

  const numDoneMin = interventiDone
    ?.map((item) =>
      parseInt(item?.duration?.substring(item?.duration?.indexOf(":") + 1))
    )
    .reduce((prev, curr) => prev + curr, 0);
  const numDoneMinHour = numDoneMin / 60;
  const totHour = Math.trunc(numDoneMinHour + numDoneHours);
  const min = Math.trunc((numDoneMinHour - Math.trunc(numDoneMinHour)) * 60);
  const indexLastPack = pack?.headers?.length - 1;
  var tempoSpeso = 0;
  var tempoPacco = 0;
  var tempoPacchetto = 0;
  var tempoUsato = 0;
  var tempoMinuti = 0;
  var tempoOre = 0;
  var perc = 0;
  if (pack.headers !== undefined && Object.keys(pack?.headers).length !== 0) {
    tempoSpeso =
      pack.headers[indexLastPack].spent_time_in_hours >
      pack.headers[indexLastPack].estimated_time_in_hours
        ? 100
        : (100 / pack.headers[indexLastPack].estimated_time_in_hours) *
          pack.headers[indexLastPack].spent_time_in_hours;
    tempoPacco =
      pack.headers[indexLastPack].estimated_time_in_hours >
      pack.headers[indexLastPack].spent_time_in_hours
        ? 100
        : (100 / pack.headers[indexLastPack].spent_time_in_hours) *
          pack.headers[indexLastPack].estimated_time_in_hours;
    tempoPacchetto = pack.headers[indexLastPack].estimated_time_in_hours;
    tempoUsato = pack.headers[indexLastPack].spent_time_in_hours;
    tempoMinuti = Math.round(
      (pack.headers[indexLastPack].spent_time_in_hours -
        Math.trunc(pack.headers[indexLastPack].spent_time_in_hours)) *
        60
    );
    tempoOre = Math.trunc(pack.headers[indexLastPack].spent_time_in_hours);
    console.log("temp", tempoOre);
    perc = Math.round((100 / tempoPacchetto) * tempoUsato * 100) / 100;
  }

  return (
    <Paper elevation={3} style={{ padding: "2em", margin: "1%" }}>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="flex-end"
      >
        <Typography variant="h5" align="left">
          {" "}
          <img
            alt="tempo"
            src={tempo1}
            style={{ width: "60px", marginRight: "20px" }}
          />{" "}
          Monitoraggio del tempo
        </Typography>
        {pack.headers !== undefined &&
        Object.keys(pack?.headers).length !== 0 ? (
          <Button
            style={{ backgroundColor: "#34538a" }}
            onClick={() => dispatch(changeStatusDialog())}
            startIcon={<FormatListBulletedIcon />}
            variant="contained"
          >
            Lista pacchetti
          </Button>
        ) : (
          <></>
        )}
      </Grid>
      <Typography variant="h6" style={{ marginTop: "28px" }} align="left">
        {pack?.headers !== undefined && Object.keys(pack?.headers).length !== 0
          ? pack?.headers[indexLastPack].name
          : ""}
      </Typography>

      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
      >
        <Grid item xs={10}>
          <BorderLinearProgress variant="determinate" value={tempoSpeso} />
        </Grid>

        <Grid item xs={10}>
          <BorderLinearProgress2 variant="determinate" value={tempoPacco} />
        </Grid>
        <Grid item xs={2}>
          {tempoPacchetto !== 0 ? (
            <Typography align="right" variant="h5" style={{ color: "#bfd825" }}>
              {tempoPacchetto}h
            </Typography>
          ) : (
            <></>
          )}
        </Grid>
      </Grid>
      {tempoPacchetto !== 0 ? (
        <Typography
          align="left"
          variant="h6"
          style={{ marginTop: "1em", color: "#bfd825" }}
        >
          Pacchetto ore {tempoPacchetto}h
        </Typography>
      ) : (
        <></>
      )}
      {tempoOre !== 0 ? (
        <Typography
          align="left"
          variant="h6"
          style={{ marginTop: "1em", color: "#72849c" }}
        >
          Tempo usato {tempoOre} h {tempoMinuti} min
        </Typography>
      ) : (
        <></>
      )}
      {perc !== 0 ? (
        <Typography
          align="left"
          variant="h6"
          style={{ marginTop: "1em", color: "#72849c" }}
        >
          Percentuale usata {perc}%
        </Typography>
      ) : (
        <></>
      )}
      {pack.headers !== undefined && Object.keys(pack?.headers).length === 0 ? (
        <Typography
          align="left"
          variant="h6"
          style={{ marginTop: "1em", color: "#72849c" }}
        >
          Da fatturare {totHour} h {min} m
        </Typography>
      ) : (
        <></>
      )}
      <Grid
        container
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
      >
        {/* <img alt="tempo" src={tempo1} style={{width:"300px", marginTop:"-153px"}} /> */}
      </Grid>
    </Paper>
  );
}
