import "./App.css";
import Login from "./Components/Login/Login";
import back from "./Img/Back.jpeg";
import { Provider } from "react-redux";
import store from "./store/store/store";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./theme";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <div
            style={{ backgroundImage: `url(${back})`, height: "1500px" }}
            className="App"
          >
            <Login />
          </div>
        </LocalizationProvider>
      </Provider>
    </ThemeProvider>
  );
}

export default App;
