import React, { useState} from "react";
import { useSelector, useDispatch} from 'react-redux'
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import {  user, nazione, newCity, modifyCity, setNewCity, saveCity, newCAP,
    setNewCAP, modifyCAP, newPhone, saveCAP ,newAddress, modifyAddress, setNewAddress, saveAddress, modifyPhone, savePhone, setNewPhone,
newMobilePhone, setNewMobilePhone, modifyMobilePhone, saveMobilePhone, newFax, modifyFax, setNewFax, saveFax, modifyEmail, setNewEmail, saveEmail, newEmail} from '../../store/slice/userSlice';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CancelIcon from '@mui/icons-material/Cancel';


export default function Login() {
    const utente = useSelector(user);
    const country = useSelector(nazione);
    const newCitta = useSelector(newCity);
    const codePost = useSelector(newCAP);
    const newVia = useSelector(newAddress);
    const newTelefono = useSelector(newPhone);
    const cellulare = useSelector(newMobilePhone);
    const fax = useSelector(newFax);
    const email = useSelector(newEmail);


    const dispatch = useDispatch();
    

    const [style, setStyle] = useState({display: 'none'});
    const [style1, setStyle1] = useState({display: 'none'});
      const [style2, setStyle2] = useState({display: 'none'});
      const [style3, setStyle3] = useState({display: 'none'});
      const [style4, setStyle4] = useState({display: 'none'});
      const [style5, setStyle5] = useState({display: 'none'});
      const [style6, setStyle6] = useState({display: 'none'});
  
  return (
    <Paper style={{ padding:"2em", margin:"1%"}}>
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
            >
      <img alt="Logo"  style={{width:"100px", marginRight:"20px"}}src={`data:image/jpeg;base64,${utente.profile_image}`}/>
        <Typography variant="h5">{utente.name_1}</Typography>
        </Grid>

        <TableContainer >
      <Table stickyHeader sx={{ minWidth: 650, marginTop:"30px" }} aria-label="simple table">
        <TableBody>

            <TableRow   >
                                                                    {/* Field VIA */}
              <TableCell component="th" scope="row">
              <Typography variant="h6" style={{fontWeight:"bold"}}  >Via:</Typography>
              </TableCell>
              {newVia.modify !== true ? 
              <TableCell align="left">  <div style={{width:"250px", height:"30px"}}   onMouseEnter={e => {
                     setStyle2({display: 'block', maxHeight:"30px"});
                 }}
                 onMouseLeave={e => {
                     setStyle2({display: 'none'})
                 }}>
                        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
        >
      
        <Typography style={{minWidth:"200px"}} variant="h6" >{utente.address}</Typography>
        <IconButton onClick={() => dispatch(modifyAddress())} size="small" style={style2}>
          <EditIcon fontSize="small" />
        </IconButton>
        </Grid>
        </div></TableCell>:<TableCell align="left">                        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
        >       
        <TextField style={{minWidth:"150px"}} onChange={(e) => dispatch(setNewAddress(e.target.value))} value={newVia.value} id="standard-basic" variant="standard" />
        <IconButton onClick={() => dispatch(saveAddress())} size="small" style={style2}>
          <CheckCircleOutlineIcon fontSize="small" />
        </IconButton>
        <IconButton onClick={() => dispatch(modifyAddress())} size="small" style={style2}>
          <CancelIcon fontSize="small" />
        </IconButton> </Grid></TableCell>}

                                                                              {/* Field CAP */}

              <TableCell align="left"> <Typography variant="h6" style={{ fontWeight:"bold"}}  >CAP:</Typography></TableCell>
              {codePost.modify !== true ? 
              <TableCell align="left">  <div style={{width:"200px", height:"30px"}}   onMouseEnter={e => {
                     setStyle1({display: 'block', maxHeight:"30px"});
                 }}
                 onMouseLeave={e => {
                     setStyle1({display: 'none'})
                 }}>
                        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
        >
      
        <Typography variant="h6" >{utente.postcode}</Typography>
        <IconButton onClick={() => dispatch(modifyCAP())} size="small" style={style1}>
          <EditIcon fontSize="small" />
        </IconButton>
        </Grid>
        </div></TableCell>:<TableCell align="left">                        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
        >       
        <TextField onChange={(e) => dispatch(setNewCAP(e.target.value))} value={codePost.value} id="standard-basic" variant="standard" />
        <IconButton onClick={() => dispatch(saveCAP())} size="small" style={style1}>
          <CheckCircleOutlineIcon fontSize="small" />
        </IconButton>
        <IconButton onClick={() => dispatch(modifyCAP())} size="small" style={style1}>
          <CancelIcon fontSize="small" />
        </IconButton> </Grid></TableCell>}
            </TableRow>
                                                                                  {/* Field Citta */}
            <TableRow   >
              <TableCell component="th" scope="row">
              <Typography variant="h6" style={{fontWeight:"bold"}}  >Città:</Typography>
              </TableCell>
              {newCitta.modify !== true ? 
              <TableCell align="left">  <div style={{width:"200px", height:"30px"}} onMouseEnter={e => {
                     setStyle({display: 'block', maxHeight:"30px", marginLEft:"20px"});
                 }}
                 onMouseLeave={e => {
                     setStyle({display: 'none'})
                 }}>
                        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
        >
      
        <Typography variant="h6" >{utente.city}</Typography>
        <IconButton onClick={() => dispatch(modifyCity())} size="small" style={style}>
          <EditIcon fontSize="small" />
        </IconButton>
        </Grid>
        </div></TableCell>:<TableCell align="left">                        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
        >       
        <TextField onChange={(e) => dispatch(setNewCity(e.target.value))} value={newCitta.value} id="standard-basic" variant="standard" />
        <IconButton onClick={() => dispatch(saveCity())} size="small" style={style}>
          <CheckCircleOutlineIcon fontSize="small" />
        </IconButton>
        <IconButton onClick={() => dispatch(modifyCity())} size="small" style={style}>
          <CancelIcon fontSize="small" />
        </IconButton> </Grid></TableCell>}

                                                                                           {/* Field NAZIONE */}
              
              <TableCell align="left"> <Typography variant="h6" style={{ fontWeight:"bold"}}  >Nazione:</Typography></TableCell>
              <TableCell align="left">  <div style={{width:"200px", height:"30px"}} >
                        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
        >
      
        <Typography variant="h6" >{country.name_short}</Typography>
        </Grid>
        </div></TableCell>
            </TableRow>

                                                                               {/* Field Telefono */}

            <TableRow   >
              <TableCell component="th" scope="row">
              <Typography variant="h6" style={{fontWeight:"bold"}}  >Telefono fisso:</Typography>
              </TableCell>
              {newTelefono.modify !== true ? 
              <TableCell align="left">  <div style={{width:"200px", height:"30px"}}  onMouseEnter={e => {
                     setStyle3({display: 'block', maxHeight:"30px", marginLEft:"20px"});
                 }}
                 onMouseLeave={e => {
                     setStyle3({display: 'none'})
                 }}>
                        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
        >
      
        <Typography variant="h6" >{utente.phone_fixed}</Typography>
        <IconButton onClick={() => dispatch(modifyPhone())} size="small" style={style3}>
          <EditIcon fontSize="small" />
        </IconButton>
        </Grid>
        </div></TableCell>:<TableCell align="left">                        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
        >       
        <TextField onChange={(e) => dispatch(setNewPhone(e.target.value))} value={newTelefono.value} id="standard-basic" variant="standard" />
        <IconButton onClick={() => dispatch(savePhone())} size="small" style={style3}>
          <CheckCircleOutlineIcon fontSize="small" />
        </IconButton>
        <IconButton onClick={() => dispatch(modifyPhone())} size="small" style={style3}>
          <CancelIcon fontSize="small" />
        </IconButton> </Grid></TableCell>}

                                                                                          {/* Field Cellulare */}

              <TableCell align="left"> <Typography variant="h6" style={{ fontWeight:"bold"}}  >Telefono cellulare:</Typography></TableCell>
              {cellulare.modify !== true ? 
              <TableCell align="left">  <div style={{width:"200px", height:"30px"}}  onMouseEnter={e => {
                     setStyle4({display: 'block', maxHeight:"30px", marginLEft:"20px"});
                 }}
                 onMouseLeave={e => {
                     setStyle4({display: 'none'})
                 }}>
                        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
        >
      
        <Typography variant="h6" >{utente.phone_mobile}</Typography>
        <IconButton onClick={() => dispatch(modifyMobilePhone())} size="small" style={style4}>
          <EditIcon fontSize="small" />
        </IconButton>
        </Grid>
        </div></TableCell>:<TableCell align="left">                        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
        >       
        <TextField onChange={(e) => dispatch(setNewMobilePhone(e.target.value))} value={cellulare.value} id="standard-basic" variant="standard" />
        <IconButton onClick={() => dispatch(saveMobilePhone())} size="small" style={style4}>
          <CheckCircleOutlineIcon fontSize="small" />
        </IconButton>
        <IconButton onClick={() => dispatch(modifyMobilePhone())} size="small" style={style4}>
          <CancelIcon fontSize="small" />
        </IconButton> </Grid></TableCell>}
            </TableRow>

                                                                                    {/* Field Fax */}

            <TableRow   >
              <TableCell component="th" scope="row">
              <Typography variant="h6" style={{fontWeight:"bold"}}  >Fax:</Typography>
              </TableCell>
              { fax.modify !== true ? 
              <TableCell align="left">  <div style={{width:"200px", height:"30px"}}  onMouseEnter={e => {
                     setStyle5({display: 'block', maxHeight:"30px", marginLEft:"20px"});
                 }}
                 onMouseLeave={e => {
                     setStyle5({display: 'none'})
                 }}>
                        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
        >
      
        <Typography variant="h6" >{utente.fax}</Typography>
        <IconButton onClick={() => dispatch(modifyFax())} size="small" style={style5}>
          <EditIcon fontSize="small" />
        </IconButton>
        </Grid>
        </div></TableCell>:<TableCell align="left">                        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
        >       
        <TextField onChange={(e) => dispatch(setNewFax(e.target.value))} value={fax.value} id="standard-basic" variant="standard" />
        <IconButton onClick={() => dispatch(saveFax())} size="small" style={style5}>
          <CheckCircleOutlineIcon fontSize="small" />
        </IconButton>
        <IconButton onClick={() => dispatch(modifyFax())} size="small" style={style5}>
          <CancelIcon fontSize="small" />
        </IconButton> </Grid></TableCell>}

                                                                                     {/* Field Email */}

              <TableCell align="left"> <Typography variant="h6" style={{ fontWeight:"bold"}}  >Email:</Typography></TableCell>
              { email.modify !== true ? 
              <TableCell align="left">  <div style={{width:"200px", height:"30px"}}  onMouseEnter={e => {
                     setStyle6({display: 'block', maxHeight:"30px", marginLEft:"20px"});
                 }}
                 onMouseLeave={e => {
                     setStyle6({display: 'none'})
                 }}>
                        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
        >
      
        <Typography variant="h6" >{utente.email}</Typography>
        <IconButton onClick={() => dispatch(modifyEmail())} size="small" style={style6}>
          <EditIcon fontSize="small" />
        </IconButton>
        </Grid>
        </div></TableCell>:<TableCell align="left">                        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
        >       
        <TextField onChange={(e) => dispatch(setNewEmail(e.target.value))} value={email.value} id="standard-basic" variant="standard" />
        <IconButton onClick={() => dispatch(saveEmail())} size="small" style={style6}>
          <CheckCircleOutlineIcon fontSize="small" />
        </IconButton>
        <IconButton onClick={() => dispatch(modifyEmail())} size="small" style={style6}>
          <CancelIcon fontSize="small" />
        </IconButton> </Grid></TableCell>}
            </TableRow>
    
        </TableBody>
      </Table>
    </TableContainer>
      

    
      </Paper>
  );
}