import { all, takeLatest } from "redux-saga/effects";
import {
  saveCAP,
  saveCity,
  saveAddress,
  savePhone,
  saveMobilePhone,
  saveFax,
  saveEmail,
} from "../slice/userSlice";
import doLogin, { doLogout } from "./loginSagas";
import updateCliente from "./updateSagas";

/**
 * inizializzazione reparti
 */

function* actionWatcher() {
  yield takeLatest("LOGIN", doLogin);
  yield takeLatest(saveCity.type, updateCliente);
  yield takeLatest(saveCAP.type, updateCliente);
  yield takeLatest(saveAddress.type, updateCliente);
  yield takeLatest(savePhone.type, updateCliente);
  yield takeLatest(saveMobilePhone.type, updateCliente);
  yield takeLatest(saveFax.type, updateCliente);
  yield takeLatest(saveEmail.type, updateCliente);
  yield takeLatest("LOGOUT", doLogout);
}

export default function* rootSaga() {
  yield all([actionWatcher()]);
}
