import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  interventions,
  user,
  openGraph,
  changeStatusGraph,
} from "../../store/slice/userSlice";
import { VictoryAxis, VictoryBar, VictoryChart, VictoryLabel } from "victory";
import Dialog from "@mui/material/Dialog";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import AppBar from "@mui/material/AppBar";
import ClearIcon from "@mui/icons-material/Clear";
import IconButton from "@mui/material/IconButton";
import QueryStatsIcon from '@mui/icons-material/QueryStats';

export default function Statistiche() {
  const op = useSelector(openGraph);
  const interventi = useSelector(interventions);
  const dispatch = useDispatch();
  const [value, setValue] = useState(new Date());
  const intFiltered = interventi.filter(
    (x) => x.date.includes(value.getFullYear()) && x.duration !== null
  );
  intFiltered.reverse();

  const yAxis = intFiltered.map((i) => {
    const obj = {};
    obj.y = parseFloat(
      `${i.duration.substring(0, 1)}.${(100 / 60) * i.duration.substring(2)}`
    );
    obj.x = `${i.date.substring(i.date.indexOf("-") + 4)}.${i.date.substring(
      i.date.indexOf("-") + 1,
      i.date.indexOf("-") + 3
    )}`;
    return obj;
  });
  yAxis.sort(function (a, b) {
    return a.y - b.y;
  });
  const xAxis = intFiltered.map(
    (i) =>
      `${i.date.substring(i.date.indexOf("-") + 4)}.${i.date.substring(
        i.date.indexOf("-") + 1,
        i.date.indexOf("-") + 3
      )}`
  );
  return (
    <Dialog open={op} onClose={() => dispatch(changeStatusGraph())}>
      <AppBar style={{ backgroundColor: "#34538a" }} position="static">
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography style={{ margin: "20px" }} variant="h5">
            Grafico ore
          </Typography>
          <IconButton onClick={() => dispatch(changeStatusGraph())}>
            <ClearIcon style={{ margin: "20px", color: "white" }} />
          </IconButton>
        </Grid>
      </AppBar>
      <div style={{ margin: "20px" }}>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <DatePicker
            views={["year"]}
            label="Anno"
            value={value}
            onChange={(newValue) => {
              setValue(newValue);
            }}
            renderInput={(params) => (
              <TextField {...params} helperText={null} />
            )}
          />
        </Grid>
        <Divider light style={{ marginBottom: "30px", marginTop: "20px" }} />
        <Typography
          variant="h6"
          style={{ marginLeft: "20px", marginBottom: "-30px" }}
        >
          Ore
        </Typography>
        <VictoryChart fixLabelOverlap={true} domainPadding={15}>
          <VictoryBar style={{ data: { fill: "#bfd825" } }} data={yAxis} />
          {/* Asse X tempo */}
          <VictoryAxis fixLabelOverlap tickValues={xAxis}></VictoryAxis>
          {/* Asse Y visualizzazioni */}
          <VictoryAxis dependentAxis />
        </VictoryChart>
        <Typography
          align="center"
          variant="h6"
          style={{ marginRight: "20px", marginTop: "-10px" }}
        >
          Tempo (dd.mm)
        </Typography>
      </div>
    </Dialog>
  );
}
