import { put, select, call } from "redux-saga/effects";
import {
  fetchLogin,
  getCountry,
  getInterventions,
  getOperatore,
  getPackage,
  getUser,
  getTask,
} from "../api";
import { clearLoad, setLoaded, setLoading } from "../slice/loadSlice";
import {
  username,
  password,
  setSuccess,
  clearLogin,
  setOpenAl,
  setOpenAlRete,
} from "../slice/loginSlice";
import { setOperatori } from "../slice/operatoreSlice";
import {
  setInterventions,
  setPacchetti,
  setUser,
  setNazione,
  setNewAddress,
  setNewCAP,
  setNewCity,
  setNewEmail,
  setNewFax,
  setNewPhone,
  setNewMobilePhone,
  clearUserAll,
  setTask,
} from "../slice/userSlice";

/**
 * Inizializzazione della home dell'autoanamnesi senza etichetta
 */
export default function* doLogin() {
  try {
    yield put(setLoading());
    const user = yield select(username);
    const pass = yield select(password);
    const identification = yield call(fetchLogin, user, pass);
    yield put(setSuccess(identification.data[0] !== false));
    if (identification.data[0] !== false) {
      const cliente = yield call(getUser, identification.data[0].IdBexio);
      yield put(setUser(cliente.body));
      const interventi = yield call(
        getInterventions,
        identification.data[0].IdBexio
      );
      yield put(setInterventions(interventi.body));

      const country = yield call(getCountry, cliente.body.country_id);
      const ta = yield call(getTask, identification.data[0].IdBexio);
      yield put(setTask(ta.body));
      yield put(setNazione(country.body));
      yield put(setNewAddress(cliente.body.address));
      yield put(setNewCAP(cliente.body.postcode));
      yield put(setNewCity(cliente.body.city));
      yield put(setNewEmail(cliente.body.email));
      yield put(setNewFax(cliente.body.fax));
      yield put(setNewPhone(cliente.body.phone_fixed));
      yield put(setNewMobilePhone(cliente.body.phone_mobile));
      const op = yield call(getOperatore);

      yield put(setOperatori(op.body));
      const pack = yield call(getPackage, identification.data[0].IdBexio);
      yield put(setPacchetti(pack));
    } else {
      yield put(setOpenAl());
      yield put(setLoaded());
    }

    yield put(setLoaded());
  } catch (error) {
    yield put(setOpenAlRete());
    yield put(setLoaded());
    console.error("errore", error);
  }
}

export function* doLogout() {
  try {
    yield put(clearLoad());
    yield put(clearUserAll());
    yield put(clearLogin());
  } catch (error) {
    console.error("errore", error);
  }
}
