
import { createSlice } from '@reduxjs/toolkit';

const loadSlice = createSlice({
  name: 'load',
  initialState:
  {
    loading:false,
    loaded:false,

  },
  reducers: {
    setLoading(state) {
      state.loading = true;
      state.loaded = false;
    },
    setLoaded(state) {
      state.loading= false;
      state.loaded = true;
    },
    clearLoad(state){
      state.loading= false;
      state.loaded = false;

    }
     
  },
});

export const loading = (state) => state.load.loading;
export const loaded = (state) => state.load.loaded;

export const {
  setLoaded, setLoading, clearLoad
} = loadSlice.actions;
export default loadSlice.reducer;
