import { createSlice } from "@reduxjs/toolkit";

const userSlice = createSlice({
  name: "user",
  initialState: {
    user: {},
    interventions: [],
    pacchetti: [],
    nazione: {},
    task: [],
    newAddress: { value: "", modify: false },
    newCAP: { value: "", modify: false },
    newCity: { value: "", modify: false },
    newCountry: { value: "", modify: false },
    newPhone: { value: "", modify: false },
    newMobilePhone: { value: "", modify: false },
    newFax: { value: "", modify: false },
    newEmail: { value: "", modify: false },
    openDialog: false,
    oreFatturabili: 0,
    openGraph: false,
  },
  reducers: {
    addHours(state, { payload }) {
      state.oreFatturabili = state.oreFatturabili + payload;
    },
    changeStatusDialog(state) {
      state.openDialog = !state.openDialog;
    },
    changeStatusGraph(state) {
      state.openGraph = !state.openGraph;
    },
    setTask(state, { payload }) {
      state.task = payload;
    },
    setUser(state, { payload }) {
      state.user = payload;
    },
    setInterventions(state, { payload }) {
      state.interventions = payload;
    },
    setPacchetti(state, { payload }) {
      state.pacchetti = payload;
    },
    setNazione(state, { payload }) {
      state.nazione = payload;
    },
    setNewAddress(state, { payload }) {
      state.newAddress.value = payload;
    },
    saveAddress(state) {
      state.user.address = state.newAddress.value;
      state.newAddress.modify = false;
    },
    modifyAddress(state) {
      state.newAddress.modify = !state.newAddress.modify;
    },
    setNewCAP(state, { payload }) {
      state.newCAP.value = payload;
    },
    modifyCAP(state) {
      state.newCAP.modify = !state.newCAP.modify;
    },
    saveCAP(state) {
      state.user.postcode = state.newCAP.value;
      state.newCAP.modify = false;
    },
    setNewCity(state, { payload }) {
      state.newCity.value = payload;
    },
    modifyCity(state) {
      state.newCity.modify = !state.newCity.modify;
    },
    saveCity(state) {
      state.user.city = state.newCity.value;
      state.newCity.modify = false;
    },
    setNewCountry(state, { payload }) {
      state.newCountry.value = payload;
    },

    modifyCountry(state) {
      state.newCountry.modify = !state.newCountry.modify;
    },
    setNewPhone(state, { payload }) {
      state.newPhone.value = payload;
    },
    modifyPhone(state) {
      state.newPhone.modify = !state.newPhone.modify;
    },
    savePhone(state) {
      state.user.phone_fixed = state.newPhone.value;
      state.newPhone.modify = false;
    },
    setNewMobilePhone(state, { payload }) {
      state.newMobilePhone.value = payload;
    },
    modifyMobilePhone(state) {
      state.newMobilePhone.modify = !state.newMobilePhone.modify;
    },
    saveMobilePhone(state) {
      state.user.phone_mobile = state.newMobilePhone.value;
      state.newMobilePhone.modify = false;
    },
    setNewFax(state, { payload }) {
      state.newFax.value = payload;
    },
    modifyFax(state) {
      state.newFax.modify = !state.newFax.modify;
    },
    saveFax(state) {
      state.user.fax = state.newFax.value;
      state.newFax.modify = false;
    },
    setNewEmail(state, { payload }) {
      state.newEmail.value = payload;
    },
    modifyEmail(state) {
      state.newEmail.modify = !state.newEmail.modify;
    },
    saveEmail(state) {
      state.user.email = state.newEmail.value;
      state.newEmail.modify = false;
    },
    clearUserAll(state) {
      state.user = {};
      state.interventions = [];
      state.pacchetti = [];
      state.nazione = {};
      state.task = [];
      state.newAddress = { value: "", modify: false };
      state.newCAP = { value: "", modify: false };
      state.newCity = { value: "", modify: false };
      state.newCountry = { value: "", modify: false };
      state.newPhone = { value: "", modify: false };
      state.newMobilePhone = { value: "", modify: false };
      state.newFax = { value: "", modify: false };
      state.newEmail = { value: "", modify: false };
      state.openDialog = false;
      state.oreFatturabili = 0;
    },
  },
});

export const user = (state) => state.user.user;
export const interventions = (state) => state.user.interventions;
export const pacchetti = (state) => state.user.pacchetti;
export const nazione = (state) => state.user.nazione;
export const newCity = (state) => state.user.newCity;
export const newCAP = (state) => state.user.newCAP;
export const newAddress = (state) => state.user.newAddress;
export const newPhone = (state) => state.user.newPhone;
export const newMobilePhone = (state) => state.user.newMobilePhone;
export const newFax = (state) => state.user.newFax;
export const newEmail = (state) => state.user.newEmail;
export const task = (state) => state.user.task;
export const openDialog = (state) => state.user.openDialog;
export const oreFatturabili = (state) => state.user.oreFatturabili;
export const openGraph = (state) => state.user.openGraph;
export const {
  setUser,
  setInterventions,
  setPacchetti,
  setNazione,
  modifyCity,
  setNewAddress,
  setNewCAP,
  setNewCity,
  setNewCountry,
  setNewEmail,
  setNewFax,
  setNewMobilePhone,
  setNewPhone,
  modifyAddress,
  modifyCAP,
  modifyCountry,
  modifyEmail,
  modifyFax,
  modifyMobilePhone,
  modifyPhone,
  saveCity,
  saveCAP,
  saveAddress,
  savePhone,
  saveFax,
  saveEmail,
  clearUserAll,
  saveMobilePhone,
  setTask,
  changeStatusDialog,
  addHours,
  changeStatusGraph,
} = userSlice.actions;
export default userSlice.reducer;
