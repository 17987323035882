import React from "react";
import { useDispatch, useSelector } from "react-redux";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Logo from "../../Img/Apton.png";
import Typography from "@mui/material/Typography";
import {
  setPassword,
  setUsername,
  tryLogin,
  success,
  username,
  password,
  setSuccess,
  openAl,
  closeAl,
  openAlRete,
} from "../../store/slice/loginSlice";
import Dialog from "@mui/material/Dialog";
import Interventi from "../Interventi/Interventi";
import back from "../../Img/Back.jpeg";
import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import LinearProgress from "@mui/material/LinearProgress";
import { loading } from "../../store/slice/loadSlice";

export default function Login() {
  const dispatch = useDispatch();
  const dialog = useSelector(success);
  const name = useSelector(username);
  const pass = useSelector(password);
  const al = useSelector(openAl);
  const load = useSelector(loading);
  const al1 = useSelector(openAlRete);

  const [values, setValues] = React.useState({
    amount: "",
    password: "",
    weight: "",
    weightRange: "",
    showPassword: false,
  });

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const [state] = React.useState({
    open: false,
    vertical: "top",
    horizontal: "center",
  });

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const handleClose = () => {
    dispatch(setSuccess(false));
  };
  const { vertical, horizontal } = state;

  return (
    <div>
      <Dialog fullScreen open={dialog} onClose={handleClose}>
        <div style={{ backgroundImage: `url(${back})` }}>
          <Interventi />
        </div>
      </Dialog>

      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="static" style={{ backgroundColor: "#34538a" }}>
          <Toolbar></Toolbar>
        </AppBar>

        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <Paper
            style={{
              padding: "2em",
              marginTop: "5%",
              marginLeft: "10%",
              marginRight: "10%",
            }}
          >
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
            >
              <img
                alt="logo"
                style={{ width: "40%", marginBottom: "20px" }}
                src={Logo}
              />
              <Typography style={{ marginBottom: "15%" }} variant="h3">
                Gestionale cliente
              </Typography>
              <TextField
                onChange={(e) => dispatch(setUsername(e.target.value))}
                value={name}
                fullWidth
                style={{ marginTop: "10px" }}
                label="Username"
                variant="outlined"
              />

              <FormControl
                fullWidth
                style={{ marginTop: "10px" }}
                variant="outlined"
              >
                <InputLabel htmlFor="outlined-adornment-password">
                  Password
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-password"
                  type={values.showPassword ? "text" : "password"}
                  value={pass}
                  onChange={(e) => dispatch(setPassword(e.target.value))}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {values.showPassword ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Password"
                />
              </FormControl>

              <Button
                fullWidth
                style={{ marginTop: "10px", marginBottom: "10%" }}
                onClick={() => {
                  dispatch(closeAl());
                  dispatch(tryLogin());
                }}
                variant="contained"
                color="secondary"
              >
                Login
              </Button>
            </Grid>
            {!load ? <></> : <LinearProgress />}
          </Paper>
        </Grid>
      </Box>
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={al}
        message="I love snacks"
        key={vertical + horizontal}
      >
        <Alert severity="error">Username errato o password errata</Alert>
      </Snackbar>

      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={al1}
        message="I love snacks"
        key={vertical + horizontal}
      >
        <Alert severity="error">Network error</Alert>
      </Snackbar>
    </div>
  );
}
