import { createTheme, responsiveFontSizes } from "@mui/material/styles";
import "./index.css";

let theme = createTheme({
  palette: {
    primary: {
      light: "#104c80",
      main: "#104c80",
      dark: "#104c80",
      contrastText: "#fff",
    },
    secondary: {
      light: "#bfd825",
      main: "#bfd825",
      dark: "#bfd825",
      contrastText: "#black",
    },
  },
});
theme = responsiveFontSizes(theme);

export default theme;
