import { createSlice } from "@reduxjs/toolkit";

const loginSlice = createSlice({
  name: "login",
  initialState: {
    password: "",
    username: "",
    success: false,
    openAl: false,
    openAlRete: false,
  },
  reducers: {
    setPassword(state, { payload }) {
      state.password = payload;
    },
    setUsername(state, { payload }) {
      state.username = payload;
    },
    setSuccess(state, { payload }) {
      state.success = payload;
    },
    clearLogin(state) {
      state.password = "";
      state.username = "";
      state.success = false;
    },
    setOpenAl(state) {
      state.openAl = !state.openAl;
    },
    closeAl(state) {
      state.openAl = false;
    },
    setOpenAlRete(state) {
      state.openAlRete = true;
    },
  },
});

export const tryLogin = () => ({
  type: "LOGIN",
});

export const tryLogout = () => ({
  type: "LOGOUT",
});

export const password = (state) => state.login.password;
export const username = (state) => state.login.username;
export const success = (state) => state.login.success;
export const openAl = (state) => state.login.openAl;
export const openAlRete = (state) => state.login.openAlRete;
export const {
  setPassword,
  setUsername,
  setSuccess,
  clearLogin,
  setOpenAl,
  closeAl,
  setOpenAlRete,
} = loginSlice.actions;
export default loginSlice.reducer;
