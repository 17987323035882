import React, { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Button from "@mui/material/Button";
import AppBar from "@mui/material/AppBar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { useSelector } from "react-redux";
import TextField from "@mui/material/TextField";
import { interventions, pacchetti } from "../../store/slice/userSlice";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import ClearIcon from "@mui/icons-material/Clear";
import IconButton from "@mui/material/IconButton";
import PDF from "../PDF/PDF";

export default function CreaPDF() {
  const [alignment, setAlignment] = React.useState("tutti");

  const interventi = useSelector(interventions);
  const pack = useSelector(pacchetti);
  const [value, setValue] = useState();
  const [openPDF, setOpenPDF] = useState(false);

  const today = new Date();
  const [da, setDa] = React.useState(
    new Date(`${today.getFullYear()}-${today.getMonth()}-${today.getDate()}`)
  );

  const handleChangeDa = (newValue) => {
    setDa(newValue);
  };
  const [a, setA] = React.useState(new Date());

  const handleChangeA = (newValue) => {
    setA(newValue);
  };
  const handleChange = (event, newAlignment) => {
    setAlignment(newAlignment);
  };

  let interventiPackFilter = interventi.filter(
    (i) => i.pr_package_id === value
  );

  const interventiTimeFilter = interventi.filter(
    (i) => new Date(i.date) < a && new Date(i.date) > da
  );

  const handleChanges = (event) => {
    setValue(Number(event.target.value));
  };

  useEffect(() => {
    if (pack !== undefined && pack.headers !== undefined) {
      setValue(pack?.headers[0]?.id);
    }
  }, [pack]);

  const listPack = pack?.headers?.map((p, index) => (
    <FormControlLabel
      value={p?.id}
      control={
        <Radio
          sx={{
            color: "#34538a",
            "&.Mui-checked": {
              color: "#bfd825",
            },
          }}
        />
      }
      label={p?.name}
    />
  ));

  return (
    <>
      <Paper style={{ padding: "20px" }}>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <ToggleButtonGroup
            style={{ margin: "20px" }}
            value={alignment}
            exclusive
            onChange={handleChange}
          >
            <ToggleButton value="tutti">Tutti</ToggleButton>
            <ToggleButton value="periodo">Periodo</ToggleButton>
            <ToggleButton value="pacchetto">Pacchetto</ToggleButton>
          </ToggleButtonGroup>
        </Grid>
        {alignment === "pacchetto" ? (
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="flex-start"
            style={{ marginLeft: "20px" }}
          >
            <FormControl>
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={value}
                onChange={handleChanges}
              >
                {listPack}
              </RadioGroup>
            </FormControl>
          </Grid>
        ) : (
          <></>
        )}
        {alignment === "periodo" ? (
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <DesktopDatePicker
              label="Da"
              value={da}
              inputFormat="dd/M/yyyy"
              onChange={handleChangeDa}
              renderInput={(params) => (
                <TextField type="date" {...params} style={{ margin: "5px" }} />
              )}
            />
            <DesktopDatePicker
              label="a"
              value={a}
              inputFormat="dd/M/yyyy"
              onChange={handleChangeA}
              renderInput={(params) => (
                <TextField {...params} style={{ margin: "5px" }} />
              )}
            />
          </Grid>
        ) : (
          <></>
        )}
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          {alignment === "pacchetto" ? (
            <Typography
              style={{
                margin: "10px",
                borderColor: "#104c80",
                borderWidth: "2px",
                borderStyle: "solid",
                padding: "10px",
                borderRadius: "20px",
              }}
              variant="h5"
            >
              {interventiPackFilter?.length} interventi
            </Typography>
          ) : (
            <></>
          )}
          {alignment === "tutti" ? (
            <Typography
              style={{
                margin: "10px",
                borderColor: "#104c80",
                borderWidth: "2px",
                borderStyle: "solid",
                padding: "10px",
                borderRadius: "20px",
              }}
              variant="h5"
            >
              {interventi?.length} interventi
            </Typography>
          ) : (
            <></>
          )}
          {alignment === "periodo" ? (
            <Typography
              style={{
                margin: "10px",
                borderColor: "#104c80",
                borderWidth: "2px",
                borderStyle: "solid",
                padding: "10px",
                borderRadius: "20px",
              }}
              variant="h5"
            >
              {interventiTimeFilter?.length} interventi
            </Typography>
          ) : (
            <></>
          )}
        </Grid>

        <Button
          fullWidth
          style={{
            backgroundColor: "#bfd825",
            color: "#104c80",
            marginTop: "20px",
          }}
          onClick={() => setOpenPDF(true)}
          variant="contained"
        >
          Crea
        </Button>
      </Paper>

      <Dialog
        maxWidth={"xl"}
        open={openPDF}
        onClose={() => {
          setOpenPDF(false);
        }}
      >
        <AppBar style={{ backgroundColor: "#34538a" }} position="static">
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography style={{ margin: "20px" }} variant="h5">
              PDF
            </Typography>
            <IconButton onClick={() => setOpenPDF(false)}>
              <ClearIcon style={{ margin: "20px", color: "white" }} />
            </IconButton>
          </Grid>
        </AppBar>
        <PDF
          al={a}
          dal={da}
          alignment={alignment}
          interventi={
            alignment === "pacchetto"
              ? interventiPackFilter
              : alignment === "periodo"
              ? interventiTimeFilter
              : interventi
          }
        />
      </Dialog>
    </>
  );
}
