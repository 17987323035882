import {
     select,call
 } from 'redux-saga/effects';
 import {  updateUser } from '../api';
 import { user  } from '../slice/userSlice';
 
 
 /**
  * Inizializzazione della home dell'autoanamnesi senza etichetta
  */
 export default function* updateCliente() {
   try {

     const utente = yield select(user);
     const id = utente.id;
     const data= utente;
    yield call(updateUser,id, data);
 
    
 
 } catch (error) {
   console.error('errore', error);
 }
 }
 